<template>
  <div class="box" ref="box">
    <v-row style="height: 30vh; margin-top: 20vh;">
      <v-col offset="1" cols="10">
        <p
            data-sa="flip-bottom"
			data-sa-delay="500"
            style="text-align: center;"
            class="text-h4 indigo--text text--darken-4 font-weight-bold"
            v-html="$t('download.title')"></p>
        <p
            data-sa="flip-bottom"
			data-sa-delay="500"
            style="text-align: center;"
            class="text-subtitle2 indigo--text text--darken-4"
            v-html="$t('download.description')"></p>
      </v-col>
    </v-row>
    <v-row v-if="['Windows', 'Mac OS'].includes(getOS())">
      <v-col offset="1" cols="10" align="center">
        <v-btn
            x-large
            depressed
            dark tile
            @click="download"
            color="indigo">{{ $t('download.latest') }}</v-btn>
        <p class="mt-2 text-caption">{{ $t('download.version') + ': ' + version }}</p>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col offset="1" cols="10" align="center">
        <p class="mt-6 text-h5">{{ $t('download.onlyWindows') }}</p>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from 'axios';
import yaml from 'yaml-js';

export default {
  name: 'Download',
  data: () => ({
    version: '0.0.0',
    url: '',
  }),
  async created() {
    if ( this.getOS() === 'Mac OS' ) {
      const res = await axios.get('https://sopia-v3.s3.ap-northeast-2.amazonaws.com/latest-mac.yml');
      const json = yaml.load(res.data);
      this.version = json.version;
      const file = json.files.find((file) => file.url.includes('dmg'));
      this.url = `https://sopia-v3.s3.ap-northeast-2.amazonaws.com/${file.url}`;
    } else {
      const res = await axios.get('https://api.sopia.dev/contents/latest/');
      const latest = res.data.data[0];
      this.version = latest.version;
      this.url = `https://sopia-v3.s3.ap-northeast-2.amazonaws.com/${latest.files[0].url}`;
    }
    console.log('os', this.getOS());
    console.log('version', this.version);
    console.log('url', this.url);
  },
  methods: {
    download() {
      window.open(this.url);
    },
    getOS() {
      const userAgent = window.navigator.userAgent,
          platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
          macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
          windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
          iosPlatforms = ['iPhone', 'iPad', 'iPod'];
      let os = null;

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
      } else if (/Android/.test(userAgent)) {
        os = 'Android';
      } else if (/Linux/.test(platform)) {
        os = 'Linux';
      }

      return os;
    },
  },
};
</script>
